import React from 'react';
import { hydrate } from 'react-dom';
import FaqContact from '../server/components/TeliaACEKnowledgeContact';

const initialStateFaqContact = window.__INITIAL_STATE_FAQ_CONTACT__;
delete window.__INITIAL_STATE_FAQ_CONTACT__;
const faqContactRoot = document.getElementById('faq-contact-app');

if (faqContactRoot) {
  hydrate((
    <FaqContact
      interfaceUrl={initialStateFaqContact.interfaceUrl}
    />
  ), faqContactRoot);
}
